import * as cookies from "js-cookie";
import { Logger } from "../logger/logger";
import { orThrow } from "../util/or-throw";
import { Classes } from "../util/classes";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Injectable, Injector } from "@angular/core";

// EDP/GDPR cookie summary: https://gdpr.eu/cookies
const EDP_GDPR_POLICY_EXPIRY_DAYS = 365;

@Injectable({
    providedIn: "root"
})
export class CookieStorage {
    public constructor(private readonly injector: Injector) {
    }

    public static setCookie<T>(key: string, value: T): void {

        // See more in https://jira.autorola.org/browse/IN-4377
        if (Classes.isClass(value)) {

            throw new ReferenceError("Classes cannot be serialized directly");
        }

        cookies.set(
            key,
            JSON.stringify(value),
            {"expires": EDP_GDPR_POLICY_EXPIRY_DAYS}
        );

    }

    public static removeCookie(key: string): void {
        cookies.remove(key);
    }

    public static isCookie(key: string): boolean {

        return Boolean(cookies.get(key));
    }

    public getCookie<T>(key: string): T | undefined {
        if (!CookieStorage.isCookie(key)) {
            return undefined;

        }
        const value: string = orThrow(cookies.get(key));

        try {

            return JSON.parse(value) as T;

        } catch (err: unknown) {
            const log: Logger = this.injector.get<Logger>(Logger);
            log.error(
                `Could not parse JSON ${value}`,
                err
            );

            return undefined;
        }
    }
}
