export interface BuildProperties {
    readonly appBuildDate: string;
    readonly appBuildBranch: string;
    readonly appBuildNumber: string;
    readonly appVersion: string;
}

/* eslint-disable no-template-curly-in-string */
export const BUILD_PROPERTIES: BuildProperties = {
    appBuildDate: "08-06-2023 07:41:35 UTC",
    appBuildBranch: "trunk",
    appBuildNumber: "45",
    appVersion: "1.0.0"
};
/* eslint-enable no-template-curly-in-string */
