import type { BuildProperties } from "./app.build.properties";
import { BUILD_PROPERTIES } from "./app.build.properties";
import { ENV } from "./app-env-url-resolver";

interface Host {
    specFeedWS: string;
    ntkWs: string;
}

export interface AppSettings extends Host {
    readonly buildProperties: BuildProperties;

    appTitle: string;
}

/* eslint-disable @typescript-eslint/no-unnecessary-condition */
const API_HOST: Host = {
    ntkWs: ENV === ("main1" || "staging1" || "prod1") ?
      `https://ntk.${ENV}.indicata.com` :
      "https://indicata-ntk.atacidni.com",
    specFeedWS: ENV === ("main1" || "staging1" || "prod1") ?
      `https://pevc-api.${ENV}.indicata.com` :
      `https://pevc-api-${ENV}.atacidni.com`
};
/* eslint-enable @typescript-eslint/no-unnecessary-condition */

export const SETTINGS: AppSettings = {
    ntkWs: API_HOST.ntkWs,
    specFeedWS: API_HOST.specFeedWS,
    buildProperties: BUILD_PROPERTIES,
    appTitle: "Spec-feed"
};
