import { Injectable } from "@angular/core";
import type { Token } from "./token";
import { map } from "rxjs/operators";
import type { Observable } from "rxjs";
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthUserService } from "../auth-user.service";
/* eslint-enable @typescript-eslint/consistent-type-imports */
import { SETTINGS } from "../../app-settings";


@Injectable()
export class TokenRepository {
    private static readonly HOST: string = SETTINGS.ntkWs;
    private static readonly URL: string = `${TokenRepository.HOST}/oauth/token`;

    private static readonly REQ_OPTS = {
        headers: new HttpHeaders({
            "Content-type": "application/x-www-form-urlencoded; charset=utf-8",
            "Authorization": "Basic d2ViX2NsaWVudDp3UnZ2QWJtNVlRS1IzTUNNNA=="
        }),
        withCredentials: true
    };

    public constructor(private readonly http: HttpClient, private readonly authUserService: AuthUserService) {
    }

    public getCurrentToken(): Observable<Token> {
        return this.http.get<Token>(`${TokenRepository.HOST}/oauth/token/access`, {withCredentials: true});
    }

    public acquireToken(username: string, password: string): Observable<Token> {
        const body: string =
            `username=${encodeURIComponent(username)}&` +
            `password=${encodeURIComponent(password)}&grant_type=password`;

        return this.http.post<Token>(TokenRepository.URL, body, TokenRepository.REQ_OPTS);
    }

    public refreshToken(currentRefreshToken: string): Observable<Token> {
        const body = `grant_type=refresh_token&refresh_token=${encodeURIComponent(currentRefreshToken)}`;

        return this.http.post<Token>(TokenRepository.URL, body, TokenRepository.REQ_OPTS)
            .pipe(map(token => {
                this.authUserService.updateUserFromToken(token);

                return token;
            }));
    }

    public deleteToken(accessToken: string): Observable<boolean> {
        const url = `${TokenRepository.URL}/access/${accessToken}`;

        return this.http.delete(url, TokenRepository.REQ_OPTS)
            .pipe(map(() => true));
    }
}
