import { NgModule } from "@angular/core";
import type { Routes } from "@angular/router";
import { RouterModule } from "@angular/router";
import type { LoginModule } from "./interfaces/modules/login/login.module";
import type { DataSourceModule } from "./interfaces/modules/data-source/data-source.module";
import type { CandidateModule } from "./interfaces/modules/candidate/candidate.module";
import type { AttributeMappingModule } from "./interfaces/modules/mapping/attribute-mapping.module";

const routes: Routes = [
    {
        path: "",
        redirectTo: "/candidate",
        pathMatch: "full"
    },
    {
        path: "login",
        loadChildren: async():
            Promise<LoginModule> => import("./interfaces/modules/login/login.module")
            .then(module => module.LoginModule)
    },
    {
        path: "candidate",
        loadChildren: async():
            Promise<CandidateModule> => import("./interfaces/modules/candidate/candidate.module")
            .then(module => module.CandidateModule)
    },

    {
        path: "data-source",
        loadChildren: async():
            Promise<DataSourceModule> => import("./interfaces/modules/data-source/data-source.module")
            .then(module => module.DataSourceModule)
    },
    {
        path: "mapping",
        loadChildren: async():
            Promise<AttributeMappingModule> => import("./interfaces/modules/mapping/attribute-mapping.module")
            .then(module => module.AttributeMappingModule)
    }
];

@NgModule({
    "imports": [RouterModule.forRoot(routes)],
    "exports": [RouterModule]
})
export class AppRoutingModule {
}
