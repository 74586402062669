import { Injectable } from "@angular/core";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import type { NgbModalOptions, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import type { AppError } from "../../application/error/app-error";
import type { ModalContent } from "./modal-content";
import { ModalReference } from "./modal-reference";

@Injectable({
    providedIn: "root"
})
export class ModalService {
    public constructor(private readonly internalModal: NgbModal) {

    }

    public openModal(
        content: unknown,
        modalContent?: AppError,
        options?: NgbModalOptions
    ): ModalReference<boolean> {
        const modalRef: NgbModalRef = this.internalModal.open(
            content,
            options
        );

        if (modalContent) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            modalRef.componentInstance.appError = modalContent;
        }

        return new ModalReference(modalRef);
    }

    public openConfirmModal(
        content: unknown,
        modalContent?: ModalContent,
        options?: NgbModalOptions
    ): ModalReference<boolean> {
        const modalRef: NgbModalRef = this.internalModal.open(
            content,
            options
        );

        if (modalContent) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            modalRef.componentInstance.modalContent = modalContent;
        }

        return new ModalReference(modalRef);
    }
}
