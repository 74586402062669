import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { HttpUtils, HttpStatusCodes as StatusCode } from "../../http/core-http-utils";
import { ResponseMessageExtractor } from "./error-response-message-extractor";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Broadcaster } from "../broadcaster/broadcaster";
import { AppEvent } from "../broadcaster/app.event";
import { AppError } from "./app-error";
import { HttpFamily } from "../../http/http-family";

@Injectable({
    providedIn: "root"
})
export class ErrorNotifier {
    public constructor(private readonly broadcaster: Broadcaster) {

    }

    public notifyError(error: unknown): void {
        if (error instanceof HttpErrorResponse) {
            if (error.status === StatusCode.FORBIDDEN) {
                const event: AppError = AppError.unAuthorized();
                this.broadcaster.broadcast(
                    AppEvent.Unauthorized,
                    event
                );
            } else if (error.status !== StatusCode.NOT_MODIFIED) {
                if (HttpUtils.family(error.status) === HttpFamily.CLIENT_ERROR) {

                    const message: string | undefined = ResponseMessageExtractor.extract(error),
                        event: AppError = AppError.bug(message);
                    this.broadcaster.broadcast(
                        AppEvent.ClientErrorOccurred,
                        event
                    );
                } else if ([502, 503, 504].includes(error.status)) {
                    const message: string | undefined = ResponseMessageExtractor.extract(error),
                        event: AppError = AppError.serverServiceError(message);
                    this.broadcaster.broadcast(AppEvent.ServerErrorOccurred, event);
                } else {
                    const message: string | undefined = ResponseMessageExtractor.extract(error);
                    const event: AppError = AppError.bug(message);
                    this.broadcaster.broadcast(AppEvent.ServerErrorOccurred, event);
                }
            }
        } else {
            const event: AppError = AppError.bug("Code Error");
            this.broadcaster.broadcast(AppEvent.ClientErrorOccurred, event);
        }
    }
}
