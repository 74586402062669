import { ErrorType } from "./error-type";

export class AppError {
    private constructor(
        public readonly errorType: ErrorType,
        public readonly message?: string) {
    }

    public static bug(message?: string): AppError {
        return new AppError(
            ErrorType.Bug,
            message
        );
    }

    public static serverServiceError(message?: string): AppError {
        return new AppError(
            ErrorType.ServerServiceError,
            message
        );
    }

    public static unAuthorized(): AppError {
        return new AppError(ErrorType.Unauthorized);
    }
}
