import type { OnInit } from "@angular/core";
import { Component } from "@angular/core";
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Broadcaster } from "./application/broadcaster/broadcaster";
import { Router } from "@angular/router";
import { ModalService } from "./interfaces/modal/modal.service";
/* eslint-enable @typescript-eslint/consistent-type-imports */
import { AppEvent } from "./application/broadcaster/app.event";
import type { AppError } from "./application/error/app-error";
import { ErrorComponent } from "./interfaces/modal/error.component";
import type { ModalReference } from "./interfaces/modal/modal-reference";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit {
    public modalOpen: boolean | undefined;

    public constructor(
        private readonly broadcaster: Broadcaster,
        private readonly modalService: ModalService,
        private readonly router: Router
    ) {
    }

    public ngOnInit(): void {
        this.addGlobalEventHandlers();
    }

    private addGlobalEventHandlers(): void {
        // GLOBAL application events, should not be unsubscribed
        this.broadcaster
            .on(AppEvent.AuthenticationRequired)
            .subscribe((): void => {
                /* eslint-disable @typescript-eslint/no-floating-promises */
                this.router.navigate(["login"]);
            });

        this.broadcaster.on(AppEvent.Unauthorized)
            .subscribe((appError: AppError): void => {
                this.showCommunicationErrorDialog(appError);
            });

        this.broadcaster.on(AppEvent.ClientErrorOccurred)
            .subscribe((applicationError: AppError): void => {
                this.showCommunicationErrorDialog(applicationError);
            });

        this.broadcaster.on(AppEvent.ServerErrorOccurred)
            .subscribe((applicationError: AppError): void => {
                this.showCommunicationErrorDialog(applicationError);
            });
    }

    private showCommunicationErrorDialog(applicationError: AppError): void {
        if (this.modalOpen !== undefined && !this.modalOpen) {
            const modalRef: ModalReference<boolean> = this.modalService.openModal(
                ErrorComponent,
                applicationError
            );

            this.modalOpen = true;

            modalRef.resultAsObservable()
                .subscribe(() => {
                    this.modalOpen = false;
                });
        }
    }
}
