// eslint-disable-next-line @typescript-eslint/no-type-alias
export type ValidEnv = "main" | "main1" | "prod" | "prod1" | "staging" | "staging1";

let currentEnv: ValidEnv | undefined = (["main", "staging", "prod", "main1", "staging1", "prod1"] as ValidEnv[])
  .find(env => (`spec-feed-frontend.${env}.indicata.com` || `spec-feed-frontend-cf-${env}.atacidni.com`) ===
    window.location.hostname);

if (currentEnv === undefined) {
    currentEnv = window.location.hostname === "spec-feed-frontend-cf-prod.atacidni.com" ? "prod" : "main";
}

export const ENV: ValidEnv = currentEnv;
