import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TablePagingComponent } from "../paging/table-paging.component";
import { SortableTableHeaderComponent } from "../sorting/sortable-table-header.component";
import { ConfirmModalComponent } from "../interfaces/modal/confirm-modal.component";

@NgModule({
    declarations: [TablePagingComponent, SortableTableHeaderComponent, ConfirmModalComponent],
    imports: [
        CommonModule,
        NgbModule,
        ReactiveFormsModule,
        NgSelectModule,
        FontAwesomeModule,
        FormsModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        FontAwesomeModule,
        TablePagingComponent,
        SortableTableHeaderComponent,
        ConfirmModalComponent
    ]
})
export class SharedModule {
}
