import type { ErrorHandler } from "@angular/core";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { Injector, NgZone, Injectable } from "@angular/core";
import { Logger } from "../../logger/logger";
import { ErrorNotifier } from "./error-notifier";

@Injectable({
    providedIn: "root"
})
export class AppErrorHandler implements ErrorHandler {
    // need to inject logger, since the error handler is one of the first components to
    // get loaded by angular
    // this way we can get at other services without causing a circular dependency
    public constructor(private readonly injector: Injector, private readonly ngZone: NgZone) {

    }

    public handleError(error: unknown): void {
        // https://github.com/angular/angular/issues/19984
        this.ngZone.run(() => {
            const errorNotifier: ErrorNotifier = this.injector.get(ErrorNotifier);
            errorNotifier.notifyError(error);
        });

        const log: Logger = this.injector.get(Logger);
        let serializable: unknown = error;
        if (error instanceof Error) {
            serializable = {
                message: error.message,
                name: error.name,
                stack: error.stack
            };
        }

        const readableSerializable: string = JSON.stringify(
            serializable,
            undefined,
            2
        ).replace(
            // eslint-disable-next-line require-unicode-regexp
            /\\n/g,
            "\n"
        );

        // Never re-throw error, this is the final (and global) catch-all error handler
        log.error(
            `Unhandled error: \n${readableSerializable}\n`,
            error
        );
    }
}
