export class AppErrorText {
    public static readonly CLIENT_ERROR: string = "Client Error";
    public static readonly CLIENT_ERROR_MSG: string = "Oops, it seems like we lost the connection. " +
        "Please contact support if this is a recurring issue.";
    public static readonly AUTH_ERROR: string = "Authorization Error";
    public static readonly AUTH_ERROR_MSG: string = "You do not have the required permissions to perform this action";
    public static readonly SERVER_ERROR: string = "Server Error";
    public static readonly SERVER_ERROR_MSG: string = "Server Error";
    public static readonly SERVICE_ERROR_MSG: string =
        "Oops, it seems like we have some problems with our services. " +
        "Please contact support if this is a recurring issue.";

}
