import type { HttpErrorResponse } from "@angular/common/http";
import { HttpHeader } from "../../http/core-http-utils";

export class ResponseMessageExtractor {
    public static parseContentType(value?: string): string | undefined {
        if (value === undefined) {
            return undefined;
        }

        if (value.includes(";")) {
            const typeAndCharset: string[] = value.split(";");

            return typeAndCharset[0];
        }

        return value;
    }

    public static extract(response: HttpErrorResponse): string | undefined {
        const mediaType: string | undefined = ResponseMessageExtractor
            .parseContentType(response.headers.get(HttpHeader.CONTENT_TYPE) ?? undefined);

        if (mediaType === undefined || response.error === undefined) {
            return undefined;
        }

        if (response.error instanceof Error) {
            // Not an error message from server
            // this must not happen, this is only supposed to be called when there is a real response
            throw response.error;
        }

        // Error is not a real response
        switch (mediaType) {
            case "text/plain":
                return response.error as string;
            case "text/html":
                return `${response.url ?? ""} is ${response.statusText}`;
            case "application/json":
                return response.error === null ? undefined : (response.error as Error).message;
            default:
                return undefined;
        }
    }
}
