import { Component, EventEmitter, Input, Output } from "@angular/core";
import type { Pageable } from "./pageable";
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import type { PageModel } from "./page-model";
import { Page } from "./page";

@Component({
    selector: "tbl-paging",
    templateUrl: "./table-paging.component.html"
})
export class TablePagingComponent<T> {
    public faRefresh: IconDefinition = faSyncAlt;
    @Input() public pageable: Pageable = {page: 1, size: 20};
    @Input() public page: PageModel<T> = Page.emptyPage();
    @Output() public pageChange: EventEmitter<number> = new EventEmitter<number>();

    public onPageChange(resetPage: boolean): void {
        if (resetPage) {

            /*
             * Always reset page to 1
             * https://ux.stackexchange.com/questions/62154/reset-page-when-gridview-sort-order-is-changed
             */
            this.pageable.page = 1;
        }

        // Allows the caller to listen for pageChanges directly
        this.page.pageChange = true;

        this.pageChange.emit(this.pageable.page);
    }
}
