export class Classes {

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static isInterface(obj: boolean | number | string | {}): boolean {

        return !Classes.isPrimitive(obj) &&
            obj.constructor.name === "object";

    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static isPrimitive(obj: boolean | number | string | {}): boolean {

        const tpe: string = typeof obj;

        return tpe === "number" || tpe === "boolean" || tpe === "string";

    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    public static isClass(obj: boolean | number | string | {}): boolean {

        return !Classes.isPrimitive(obj) &&
            obj.constructor.name !== "Object";

    }

}
