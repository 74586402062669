<div class="modal-header">
    <h4 class="modal-title">
        {{ errorHeader }}
    </h4>

    <button (click)="dismiss()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true"><fa-icon [icon]="faTimesCircle"></fa-icon></span>
    </button>
</div>

<div class="modal-body">
    <p>{{ errorMessage }}</p>

    <br/>
    <p>
        <small>{{ timestamp }}</small>
    </p>
    <p *ngIf="appError?.message">
        <small class="text-muted">
            {{ appError?.message }}
        </small>
    </p>
</div>

<div class="modal-footer">
    <button (click)="close()" class="btn btn-secondary" type="button">
        Close
    </button>
</div>
