// eslint-disable-next-line max-classes-per-file
import { HttpFamily } from "./http-family";

export class HttpHeader {

    public static readonly CONTENT_TYPE: string = "Content-Type";

}

/* eslint no-magic-numbers: 0 */

// https://en.wikipedia.org/wiki/List_of_HTTP_status_codes
export class HttpStatusCodes {
    // 3xx
    public static readonly NOT_MODIFIED: number = 304;

    // 4xx
    public static readonly UNAUTHORIZED: number = 401;
    public static readonly FORBIDDEN: number = 403;
    public static readonly NOT_FOUND: number = 404;
    public static readonly BAD_REQUEST: number = 400;
}

export class HttpUtils {
    public static readonly HEADER_CONTENT_TYPE: string = "Content-Type";
    public static readonly HEADER_CONTENT_VALUE_JSON: string = "application/json";
    public static readonly HEADER_DEALERID: string = "dealerId";
    public static readonly HEADER_RECAPTCHAID: string = "recaptchaId";
    public static readonly HEADER_REFERENCEID: string = "referenceId";

    public static family(statusCode: number): HttpFamily {
        const familyCode: number = Math.floor(statusCode / 100);

        switch (familyCode) {
            case 1:
                return HttpFamily.INFORMATIONAL;
            case 2:
                return HttpFamily.SUCCESSFUL;
            case 3:
                return HttpFamily.REDIRECTION;
            case 4:
                return HttpFamily.CLIENT_ERROR;
            case 5:
                return HttpFamily.SERVER_ERROR;
            default:
                return HttpFamily.OTHER;
        }
    }
}
