import { AuthUser } from "./auth-user";
import { Injectable } from "@angular/core";
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Broadcaster } from "../application/broadcaster/broadcaster";
import { TokenRepository } from "./token/token.repository";
import { AuthUserService } from "./auth-user.service";
/* eslint-enable @typescript-eslint/consistent-type-imports */
import type { Observable } from "rxjs";
import type { Token } from "./token/token";
import { map } from "rxjs/operators";


@Injectable()
export class AuthService {

    public constructor(
        private readonly broadcaster: Broadcaster, private readonly tokenRepository: TokenRepository,
        private readonly authUserService: AuthUserService
    ) {
    }

    public login(username: string, password: string): Observable<AuthUser> {

        return this.tokenRepository
            .acquireToken(
                username,
                password
            )
            .pipe(map((token: Token) => new AuthUser(
                username,
                token.authorities,
                token.access_token,
                token.token_type,
                token.full_name,
                token.refresh_token,
                token.expires_in
            )));

    }

    public logout(): void {
        const authUser: AuthUser | undefined = this.authUserService.getAuthUser();
        if (authUser !== undefined) {
            this.tokenRepository.deleteToken(encodeURIComponent(authUser.accessToken))
                .subscribe(() => {
                    this.authUserService.removeUser();
                });
        }
    }

}
