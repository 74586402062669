import { Component, Input } from "@angular/core";
import type { ModalContent } from "./modal-content";
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import type { ModalComponent } from "./modal-component";

@Component({
    selector: "confirm-modal",
    templateUrl: "./confirm-modal.component.html"
})
export class ConfirmModalComponent implements ModalComponent<boolean> {
    @Input() public modalContent: ModalContent | undefined;

    public faTimesCircle: IconDefinition = faTimesCircle;

    public constructor(public readonly activeModal: NgbActiveModal) {

    }

    public close(reason: boolean): void {
        this.activeModal.close(reason);
    }

    public dismiss(): void {
        this.activeModal.dismiss(-1);
    }
}
