import { Injectable } from "@angular/core";
import type {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpHeaders
} from "@angular/common/http";
import { HttpErrorResponse } from "@angular/common/http";

import { AppEvent } from "../application/broadcaster/app.event";
import type { Token } from "./token/token";
import type { Observable } from "rxjs";
import { throwError, BehaviorSubject, EMPTY } from "rxjs";
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Logger } from "../logger/logger";
import { Broadcaster } from "../application/broadcaster/broadcaster";
import { AuthUserService } from "./auth-user.service";
import { TokenRepository } from "./token/token.repository";
/* eslint-enable @typescript-eslint/consistent-type-imports */
import { switchMap, catchError, finalize, take, filter } from "rxjs/operators";
import type { AuthUser } from "./auth-user";
import { HttpStatusCodes } from "../http/core-http-utils";
import { SETTINGS } from "../app-settings";

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {

    private isTokenRenewalInProgress = false;

    private readonly tokenSubject: BehaviorSubject<Token | undefined> =
        new BehaviorSubject<Token | undefined>(undefined);

    private static addToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {

        const headers: HttpHeaders = req.headers.set(
            "Authorization",
            `Bearer ${token}`
        );

        return req.clone({headers});
    }

    public constructor(
        private readonly authUserService: AuthUserService,
        private readonly userService: AuthUserService,
        private readonly logger: Logger,
        private readonly tokenRepository: TokenRepository,
        private readonly broadcaster: Broadcaster
    ) {
    }

    // eslint-disable-next-line max-lines-per-function
    public intercept(
        req: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        const currentUser: AuthUser | undefined = this.authUserService.getAuthUser();
        if (currentUser === undefined) {
            this.broadcaster.broadcast(AppEvent.UserSignedOut);

            return next.handle(req);
        }

        /*
         * If NTK token request (get current token), then we shouldn't attach the Bearer
         * token on the header. Also note that this acts as a circuit breaker which prevents infinite loops due
         * to the token renewal logic below.
         */
        if (req.url.startsWith(`${SETTINGS.ntkWs}/oauth/token`) ||
            req.url.includes("indicata-spec-feed-source-main.s3.eu-west-1.amazonaws.com")) {

            return next.handle(req);
        }

        return next.handle(AuthHttpInterceptor.addToken(
            req,
            currentUser.accessToken
        ))
            // eslint-disable-next-line max-lines-per-function
            .pipe(catchError((errorResponse: unknown) => {

                if (errorResponse instanceof HttpErrorResponse &&
                    errorResponse.status === HttpStatusCodes.UNAUTHORIZED) {

                    if (!this.isTokenRenewalInProgress) {

                        /*
                         * Set isTokenRenewalInProgress to true so no more calls come in and trigger multiple
                         * Refresh token calls
                         */
                        this.isTokenRenewalInProgress = true;
                        this.logger.info("Attempting to prevent double submit");

                        /*
                         * Reset here so that the following requests wait until the token
                         * Comes back from either the getCurrentToken() or the refreshToken() call.
                         */
                        this.tokenSubject.next(undefined);

                        // Fetch current access token from /oauth/token/access (using cookie)
                        return this.tokenRepository.getCurrentToken()
                            .pipe(
                                switchMap((token: Token) => {

                                    // Renewal of expired or proactive renewal unexpired token?
                                    if (token.expires_in > 10) {

                                        // Update current token and retry make a request

                                        return this.updateToken(
                                            req,
                                            next,
                                            token
                                        )
                                            .pipe(catchError((error: unknown) => {

                                                if (error instanceof HttpErrorResponse &&
                                                    error.status !== HttpStatusCodes.UNAUTHORIZED) {

                                                    return throwError(error);
                                                }

                                                return this.refreshToken(
                                                    req,
                                                    next,
                                                    token
                                                );
                                            }));
                                    }

                                    return this.refreshToken(
                                        req,
                                        next,
                                        token
                                    );
                                }),
                                catchError((error: unknown) => this.tokenSubject
                                    .pipe(switchMap((token: Token | undefined) => {

                                        if (token === undefined && error instanceof HttpErrorResponse &&
                                            /* eslint-disable function-paren-newline */
                                            error.url?.startsWith(
                                                `${SETTINGS.ntkWs}/oauth/token`) === true) {

                                            this.authUserService.removeUser();
                                            this.broadcaster.broadcast(AppEvent.UserSignedOut);

                                            return EMPTY;
                                        }

                                        return throwError(error);
                                    }))),
                                finalize(() => {
                                    this.isTokenRenewalInProgress = false;
                                })
                            );
                    }

                    /*
                     * Wait until tokenSubject contains value other than undefined,
                     * And then take(1) to complete the stream.
                     */
                    return this.tokenSubject
                        .pipe(
                            filter((token: Token | undefined) => token !== undefined),
                            take(1),
                            switchMap((token: Token | undefined) => {

                                if (token !== undefined) {
                                    return next.handle(AuthHttpInterceptor.addToken(
                                        req,
                                        token.access_token
                                    ));
                                }

                                return EMPTY;
                            })
                        );
                }

                // Rethrow for others to check
                return throwError(errorResponse);
            }));

    }

    private updateToken(
        req: HttpRequest<unknown>,
        next: HttpHandler, token: Token
    ): Observable<HttpEvent<unknown>> {

        const ntkAccessToken: string = token.access_token;

        // Update access token in user service
        this.userService.updateUserFromToken(token);

        // Notify observers about the (possibly) new access token
        this.tokenSubject.next(token);

        this.logger.info(`Retrying request with updated token: ${ntkAccessToken}`);

        // Retry request
        return next.handle(AuthHttpInterceptor.addToken(
            req,
            ntkAccessToken
        ));

    }

    private refreshToken(
        req: HttpRequest<unknown>,
        next: HttpHandler, token: Token
    ): Observable<HttpEvent<unknown>> {

        return this.tokenRepository.refreshToken(token.refresh_token)
            .pipe(switchMap((refreshToken: Token) => {

                this.logger.info("Token refreshed");

                return this.updateToken(
                    req,
                    next,
                    refreshToken
                )
                    .pipe(catchError((error: unknown) => {

                        if (error instanceof HttpErrorResponse && error.status === HttpStatusCodes.UNAUTHORIZED) {
                            this.broadcaster.broadcast(AppEvent.UserSignedOut);

                            return EMPTY;
                        }

                        return throwError(error);
                    }));
            }));
    }

}
