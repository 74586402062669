import type { Sort } from "../sorting/sort";

export class Page<T> {

    public content: T[] = [];
    public sort: Sort[] = [];

    public readonly last: boolean = false;
    public readonly first: boolean = false;

    public readonly totalElements: number = 0;
    public readonly totalPages: number = 0;

    public readonly size: number = 20;
    public readonly number: number = 10;

    public constructor(
        content: T[],
        sort: Sort[],
        last: boolean,
        first: boolean,
        totalElements: number,
        totalPages: number,
        size: number,
        number: number
    ) {

        this.content = content;
        this.sort = sort;
        this.size = size;
        this.last = last;
        this.first = first;
        this.number = number;
        this.totalElements = totalElements;
        this.totalPages = totalPages;
    }

    public static emptyPage<T>(): Page<T> {

        return new Page(
            [],
            [],
            true,
            true,
            0,
            0,
            0,
            0
        );

    }

}
