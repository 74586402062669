/**
 * Returns ref, but throws exception if ref is undefined or null
 * use this to assert defined-ness of variables/parameters
 */
// eslint-disable-next-line @typescript-eslint/no-type-alias
type orThrowDef = <T>(ref: T | null | undefined, error?: ReferenceError | string) => T;

export const orThrow: orThrowDef = <T>(ref: T | null | undefined, error?: ReferenceError | string): T => {
    if (ref === undefined || ref === null) {
        throw error === undefined ?
            new ReferenceError("Variable is undefined") :
            error instanceof ReferenceError ? error : new ReferenceError(error);
    }

    return ref;
};
