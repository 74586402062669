import { AppError } from "../../application/error/app-error";
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Logger } from "../../logger/logger";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
/* eslint-enable @typescript-eslint/consistent-type-imports */
import { ErrorType } from "../../application/error/error-type";
import { AppErrorText } from "../../application/error/app-error-text";
import type { OnInit } from "@angular/core";
import { Component, Input } from "@angular/core";
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html"
})
export class ErrorComponent implements OnInit {
    @Input() public appError: AppError = AppError.bug();

    public errorHeader: string = AppErrorText.SERVER_ERROR;
    public errorMessage: string = AppErrorText.SERVER_ERROR_MSG;
    public faTimesCircle: IconDefinition = faTimesCircle;
    public timestamp: string = new Date().toISOString();

    public constructor(private readonly logger: Logger, private readonly activeModal: NgbActiveModal) {

    }

    public ngOnInit(): void {
        switch (this.appError.errorType) {
            case ErrorType.Unauthorized:
                this.errorHeader = AppErrorText.AUTH_ERROR;
                this.errorMessage = AppErrorText.AUTH_ERROR_MSG;
                break;
            // Both server and client bug
            case ErrorType.Bug:
                this.errorHeader = AppErrorText.CLIENT_ERROR;
                this.errorMessage = AppErrorText.CLIENT_ERROR_MSG;
                break;
            case ErrorType.ServerServiceError:
                this.errorHeader = AppErrorText.SERVER_ERROR;
                this.errorMessage = AppErrorText.SERVICE_ERROR_MSG;
                break;

            default:
                this.logger.error(`unexpected errorType ${this.appError.errorType}`);
        }
    }

    public close(reason?: undefined): void {
        this.activeModal.close(reason);
    }

    public dismiss(): void {
        this.activeModal.dismiss(-1);
    }
}
