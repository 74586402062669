// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import type { Observable } from "rxjs";
import { from } from "rxjs";

export class ModalReference<T> {
    public componentInstance: unknown;

    public constructor(private readonly internalRef: NgbModalRef) {

    }

    public close(reason: T): void {
        this.internalRef.close(reason);
    }

    public dismiss(): void {
        this.internalRef.dismiss(-1);
    }

    // When ESC is clicked, returns (number) 1 (unless disabled, see ModalOptions)
    // When mouse is clicked outside dialog, returns (number) 0 (unless disabled, see ModalOptions)
    // When programmatically closed, returns (number) -1 (convention, may not always be the case)
    public resultAsObservable(): Observable<unknown> {
        // https://github.com/shlomiassaf/ngx-modialog/issues/188
        return from(this.internalRef.result.catch((err: Error) => err));
    }
}
