export enum AppEvent {
    ClientErrorOccurred,
    ServerErrorOccurred,
    UserSignedIn,
    UserSignedOut,
    // Not logged in
    AuthenticationRequired,
    // Does not have authorization (such as not having a needed role)
    Unauthorized

}
