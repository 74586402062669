import type { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import type { AppSettings } from "../../../app-settings";
import { SETTINGS } from "../../../app-settings";
/* eslint-disable @typescript-eslint/consistent-type-imports */
import { Router } from "@angular/router";
import { AuthUser } from "../../../auth/auth-user";
import { AuthUserService } from "../../../auth/auth-user.service";
import { AuthService } from "../../../auth/auth.service";
import { Broadcaster } from "../../../application/broadcaster/broadcaster";
import { Logger } from "../../../logger/logger";
/* eslint-enable @typescript-eslint/consistent-type-imports */
import { AppEvent } from "../../../application/broadcaster/app.event";
import { Subscription } from "rxjs";

@Component({
    selector: "app-nav",
    templateUrl: "./nav.component.html",
    styleUrls: ["./nav.component.scss"]
})
export class NavComponent implements OnInit {
    public collapsed = true;
    public settings: AppSettings = SETTINGS;
    public authUser: AuthUser | undefined;
    public subscription: Subscription = new Subscription();

    public constructor(
        public readonly router: Router,
        private readonly authUserService: AuthUserService,
        private readonly authService: AuthService,
        private readonly broadcaster: Broadcaster,
        private readonly log: Logger
    ) {
    }

    public ngOnInit(): void {
        this.authUser = this.authUserService.getAuthUser();
        this.setupEventListeners();
    }

    public signOut(): void {
        this.subscription.unsubscribe();
        this.broadcaster.broadcast(AppEvent.UserSignedOut);
    }

    private setupEventListeners(): void {
        this.broadcaster
            .on(AppEvent.UserSignedIn)
            .subscribe((user: AuthUser) => {
                this.authUserService.updateUser(user);
                this.authUser = this.authUserService.getAuthUser();
                this.log.info(user);
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.router.navigate(["data-source/overview"]);
            });

        this.broadcaster.on(AppEvent.UserSignedOut)
            .subscribe((): void => {
                this.authService.logout();
                this.authUser = undefined;
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.router.navigate(["login"]);
            });
    }

}
