import type { OnInit } from "@angular/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import type { Pageable } from "../paging/pageable";
import { NullHandling } from "./null-handling";
import { Direction } from "../paging/direction";
import type { Sort } from "./sort";
import { orThrow } from "../shared/or-throw";
import type { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { faSort, faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { Page } from "../paging/page";

@Component({
    "selector": "sortable-table-header",
    "templateUrl": "./sortable-table-header.component.html"
})

export class SortableTableHeaderComponent<T> implements OnInit {
    @Input() public pageable: Pageable = {
        "page": 1,
        "size": 0
    };

    @Input() public label = "";

    @Input() public property = "";

    @Input() public page: Page<T> = Page.emptyPage();

    /*
     * We could have used the enums Direction & NullHandling for these inputs,
     * however that would require all components to re-declare them for usage in the templates!
     * we would much rather just parse the strings (for convenience) and be very strict and
     * throw an error if something is buggy, directly in the initiation (fail-fast)
     * see more in ngOnInit()
     */
    @Input() public nullHandling?: string;
    @Input() public direction?: string;
    @Output() public readonly sortChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public faSortUp: IconDefinition = faSortUp;
    public faSort: IconDefinition = faSort;
    public faSortDown: IconDefinition = faSortDown;

    private parsedNullHandling?: NullHandling;
    private parsedDirection: Direction = Direction.ASC;

    public ngOnInit(): void {
        this.handleNull();

        if (this.direction === undefined) {
            this.parsedDirection = this.page.sort[0]?.direction;
        } else {
            switch (this.direction) {
                case "ASC":
                    this.parsedDirection = Direction.ASC;
                    break;
                case "DESC":
                    this.parsedDirection = Direction.DESC;
                    break;
                default:
                    throw new Error(`Unknown Direction: ${this.direction}`);
            }
        }

        /*
         * Force code and template to equal, to avoid confusion
         * hint: we could automagically "fix"/"correctify" mismatches here, but
         * - for developers it would be hard to debug
         * - for users, it is not certain that the initial sort could be obtained again without a reload, which is not
         * good UX, consider the following configuration:
         * code: {.., sort: {direction: DESC, nullHandling: NULLS_LAST}}
         * template: [direction]="ASC" [nullHandling]="NULLS_LAST"
         * on first load, it would be
         * "DESC nulls LAST"
         * however, when re-sorting, it would become
         * "DESC nulls FIRST" (or "ASC nulls LAST")
         * thus the user can never get to the initial sorting without doing a page reload, bad
         */
        const sort: Sort = this.getSort();
        if (this.property === sort.property) {
            // Ensure no mismatch in the Direction
            if (this.parsedDirection !== sort.direction) {
                throw new Error(`Mismatched Direction specification, 
                default=${this.property},${this.parsedDirection} <> sort=${sort.property},${sort.direction}`);
            }

            // Ensure no mismatch in the NullHandling
            if (this.parsedNullHandling !== sort.nullHandling) {
                throw new Error("Mismatched NullHandling specification" +
                    `default=${this.property},${this.parsedNullHandling ?? ""} <> ` +
                    `sort=${sort.property},${sort.nullHandling ?? ""}`);
            }
        }
    }

    public isSelected(): boolean {
        return this.getSort().property === this.property;
    }

    public hasDirection(direction: string): boolean {
        const currentDirection: Direction = this.getSort().direction;

        /* eslint @typescript-eslint/no-extra-parens: 0 */
        return (direction === "ASC" && currentDirection === Direction.ASC) ||
            (direction === "DESC" && currentDirection === Direction.DESC);

    }

    // eslint-disable-next-line max-statements
    public onSort(): void {
        const pageable: Pageable = this.getPageable(),
            {sort} = pageable;
        if (sort !== undefined) {

            if (sort.property === this.property) {

                // Invert direction
                sort.direction = sort.direction === Direction.ASC ?
                    Direction.DESC :
                    Direction.ASC;

                // Invert nulls unless undefined or NATIVE
                if (sort.nullHandling && sort.nullHandling !== NullHandling.NATIVE) {
                    sort.nullHandling = sort.nullHandling === NullHandling.NULLS_FIRST ?
                        NullHandling.NULLS_LAST :
                        NullHandling.NULLS_FIRST;
                }
            } else {
                sort.property = this.property;
                sort.direction = this.parsedDirection;
                if (this.parsedNullHandling !== undefined) {
                    sort.nullHandling = this.parsedNullHandling;
                }
            }
        }

        /*
         * This.sortable.onPageChanged();
         * onSortChanged()
         */

        /*
         * Always reset page to 1
         * https://ux.stackexchange.com/questions/62154/reset-page-when-gridview-sort-order-is-changed
         */
        pageable.page = 1;
        this.sortChange.emit(true);

    }

    private getSort(): Sort {
        return orThrow(this.getPageable().sort);
    }

    private getPageable(): Pageable {

        // Return orThrow(this.sortable.getPageable());

        return this.pageable;
    }

    private handleNull(): void {
        if (this.nullHandling !== undefined) {

            switch (this.nullHandling) {
                case "NULLS_LAST":
                    this.parsedNullHandling = NullHandling.NULLS_LAST;
                    break;
                case "NULLS_FIRST":
                    this.parsedNullHandling = NullHandling.NULLS_FIRST;
                    break;
                case "NATIVE":
                    this.parsedNullHandling = NullHandling.NATIVE;
                    break;
                default:
                    throw new Error(`Unknown NullHandling: ${this.nullHandling}`);
            }
        }
    }

}
