/* eslint no-unused-vars: 0 */

// eslint-disable-next-line no-shadow
export enum HttpFamily {
    // 1xx
    INFORMATIONAL,
    // 2xx
    SUCCESSFUL,
    // 3xx
    REDIRECTION,
    // 4xx
    CLIENT_ERROR,
    // 5xx
    SERVER_ERROR,
    // Non-standard status codes
    OTHER
}
