import type { Observable } from "rxjs";
import { Subject } from "rxjs";
import type { BroadcastEvent } from "./broadcast.event";
import type { AppEvent } from "./app.event";
import { Injectable } from "@angular/core";
import { filter, map } from "rxjs/operators";

@Injectable({
    "providedIn": "root"
})

export class Broadcaster {

    private readonly eventBus: Subject<BroadcastEvent>;

    public constructor() {
        this.eventBus = new Subject<BroadcastEvent>();
    }

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public broadcast(key: AppEvent, data?: unknown): void {
        this.eventBus.next({key, data});
    }

    public on(key: AppEvent): Observable<any> {
        return this.eventBus.asObservable()
            .pipe(
                filter((event: BroadcastEvent) => event.key === key),
                map((event: BroadcastEvent) => event.data)
            );
    }
}
