import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorComponent } from "./interfaces/modal/error.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AppErrorHandler } from "./application/error/error.handler";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NavComponent } from "./interfaces/layout/nav/nav.component";
import { FooterComponent } from "./interfaces/layout/footer/footer.component";
import { SharedModule } from "./shared/shared.module";
import { AuthService } from "./auth/auth.service";
import { AuthUserRepository } from "./auth/auth-user.repository";
import { AuthUserService } from "./auth/auth-user.service";
import { TokenRepository } from "./auth/token/token.repository";
import { Broadcaster } from "./application/broadcaster/broadcaster";
import { AuthHttpInterceptor } from "./auth/auth-http-interceptor";
import { CookieStorage } from "./storage/cookie-storage";

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        NavComponent,
        FooterComponent
    ],
    imports: [
        AppRoutingModule,
        NgbModule,
        BrowserModule,
        HttpClientModule,
        FontAwesomeModule,
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            serverLoggingUrl: "/api/logs",
            serverLogLevel: NgxLoggerLevel.OFF
        }),
        SharedModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: AppErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        CookieStorage,
        AuthService,
        AuthUserRepository,
        AuthUserService,
        TokenRepository,
        Broadcaster
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
