<div *ngIf="authUser" class="nav-bar-container">
    <nav class="navbar navbar-expand-lg navbar-light nav-bg">
        <div class="container">
            <div class="d-flex">
                <a class="navbar-brand  d-none d-lg-inline-block font-weight-bolder  text-white" href="#">
                    {{ settings.appTitle }}
                </a>

                <button
                        (click)="collapsed = !collapsed"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        class="navbar-toggler"
                        data-target="#navbarNavDropdown"
                        data-toggle="collapse"
                        type="button">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>

            <div (mouseover)="$event.stopPropagation()"
                 [ngbCollapse]="collapsed"
                 class="collapse navbar-collapse"
                 id="navbarNavDropdown">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a [routerLinkActive]="['active']" [routerLink]="['candidate']"
                           class="nav-link menu-item text-white">
                            Candidate List
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLinkActive]="['active']" [routerLink]="['data-source/overview']"
                           class="nav-link menu-item text-white" [ngClass]=
                                   "{ 'active': router.url.indexOf('data-source') !== -1 ||  router.url.indexOf('mapping') !== -1}">
                            Data Mapper
                        </a>
                    </li>
                </ul>
                <ul class="navbar-nav">
                    <li class="nav-item ml-2" style="cursor: default;">
                        <a class="nav-link text-white text-decoration-none">
                            Signed in as
                            <strong class="text-white">{{ authUser?.username }}</strong>
                        </a>
                    </li>
                    <li class="nav-item ml-2">
                        <a (click)="signOut()" class="nav-link text-white" style="cursor: pointer;">Sign Out</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
