<div class="modal-header">
    <h4 class="modal-title text-danger">
        <ng-container *ngIf="modalContent && modalContent.title; else defaultTitle">
            {{ modalContent.title }}
        </ng-container>
        <ng-template #defaultTitle>
            Are you sure you want to continue
        </ng-template>
    </h4>

    <button (click)="activeModal.dismiss()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true"><fa-icon [icon]="faTimesCircle"></fa-icon></span>
    </button>
</div>

<div class="modal-body" *ngIf="modalContent?.message">
    <div *ngIf="modalContent" class="alert alert-danger">{{ modalContent.message }}</div>
</div>

<div class="modal-footer">
    <button (click)="close(true)" class="btn btn-secondary" type="button">
        Yes
    </button>
    <button (click)="close(false)" class="btn btn-secondary" type="button">
        Cancel
    </button>
</div>
