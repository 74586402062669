<div class="pagination justify-content-center">
    <!-- trick: use page.size as this will only change AFTER the response has returned -->
    <ngb-pagination
            [(page)]="pageable.page"
            [collectionSize]="page.totalElements"
            [maxSize]="5"
            [pageSize]="page ? page.size : pageable.size"
            [rotate]="true"
            (pageChange)="onPageChange(false)"
            class="d-flex">
    </ngb-pagination>
    <span>
        <span class="ml-3 mr-1">Display Per Page:</span>
        <input (keyup.enter)="onPageChange(true)" [(ngModel)]="pageable.size" class="text-center" type="number"/>
        <button (click)="onPageChange(true)" class="btn btn-sm btn-secondary text-center ml-2" type="button">
            <fa-icon [icon]="faRefresh" aria-hidden="true"></fa-icon>
        </button>
    </span>
</div>
