export class AuthUser {
    public readonly username: string;
    public readonly roles: string[];
    public accessToken: string;
    public tokenType: string;
    public fullName: string;
    public refreshToken: string;
    public expires: number;

    public constructor(
        username: string,
        roles: string[],
        accessToken: string,
        tokenType: string,
        fullName: string,
        refreshToken: string,
        expires: number
    ) {

        this.username = username;
        this.roles = roles;
        this.accessToken = accessToken;
        this.tokenType = tokenType;
        this.fullName = fullName;
        this.refreshToken = refreshToken;
        this.expires = expires;
    }

    // eslint-disable-next-line no-warning-comments
    // TODO [PVO] Adjust to fit Spec-feed users

    /*
     * Public getRMANCountries(): string[] {
     *     const countries: string[] = [];
     *     const wssRoles: string[] = this.roles.filter(
     *         (roleI: string) =>
     *             roleI.indexOf("INDICATA_RMAN") !== -1 &&
     *             roleI.indexOf("INDICATA_WSS") === -1 &&
     *             roleI !== "INDICATA_RMAN_GLOBAL"
     *     );
     *     for (const role of wssRoles) {
     *         const country: string = role.replace("INDICATA_RMAN_", "");
     *         countries.push(country);
     *     }
     *
     *     return countries;
     * }
     */


    public hasUserIndicataBackendRole(): boolean {
        return this.roles.includes("indicata-backend");

    }
}
