import { Component } from "@angular/core";
import type { BuildProperties } from "../../../app.build.properties";
import { SETTINGS } from "../../../app-settings";

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html"
})
export class FooterComponent {
    public readonly buildProps: BuildProperties = SETTINGS.buildProperties;
    public readonly appTitle: string = SETTINGS.appTitle;
    public readonly copyrightYear: number = new Date().getFullYear();
}
