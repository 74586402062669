import { Injectable } from "@angular/core";
// eslint-disable-next-line @typescript-eslint/consistent-type-imports
import { NGXLogger } from "ngx-logger";

@Injectable({
    providedIn: "root"
})
export class Logger {

    public constructor(private readonly ngxLogger: NGXLogger) {
    }

    public trace(message: unknown, ...additional: unknown[]): void {
        this.ngxLogger.trace(message, ...additional);
    }

    public debug(message: unknown, ...additional: unknown[]): void {
        this.ngxLogger.debug(message, ...additional);
    }

    public info(message: unknown, ...additional: unknown[]): void {
        this.ngxLogger.info(message, ...additional);
    }

    public warn(message: unknown, ...additional: unknown[]): void {
        this.ngxLogger.warn(message, ...additional);
    }

    public error(message: unknown, ...additional: unknown[]): void {
        this.ngxLogger.error(message, ...additional);
    }
}
