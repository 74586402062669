<footer class="page-footer font-small text-center mt-5 mb-5">
    <div>
        {{ appTitle }}
        | {{ buildProps.appVersion }} #{{ buildProps.appBuildNumber }}
        | {{ buildProps.appBuildDate }}
        | VCS {{ buildProps.appBuildBranch === '?' ? '-' : buildProps.appBuildBranch}}
    </div>

    <!-- Copyright -->
    <div class="py-3 blue">
        © {{ copyrightYear }} Copyright <a href="https://www.autorola.com/">Autorola.com</a>
    </div>
    <!-- Copyright -->
</footer>
