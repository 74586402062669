import { Injectable } from "@angular/core";
/* eslint-disable-next-line @typescript-eslint/consistent-type-imports */
import { AuthUserRepository } from "./auth-user.repository";
import { AuthUser } from "./auth-user";
import type { Token } from "./token/token";

@Injectable()
export class AuthUserService {
    public constructor(private readonly authUserRepository: AuthUserRepository) {
    }

    public getAuthUser(): AuthUser | undefined {
        return this.authUserRepository.getAuthUser();
    }

    public updateUserFromToken(token: Token): void {
        const updatedUser: AuthUser = new AuthUser(
            token.user_name,
            token.authorities,
            token.access_token,
            token.token_type,
            token.full_name,
            token.refresh_token,
            token.expires_in
        );

        this.authUserRepository.setAuthUser(updatedUser);

    }

    public updateUser(user: AuthUser): void {
        this.authUserRepository.setAuthUser(user);
    }

    public removeUser(): void {
        this.authUserRepository.removeAuthUser();
    }
}
