import { Injectable } from "@angular/core";
/* eslint-disable-next-line @typescript-eslint/consistent-type-imports */
import { Broadcaster } from "../application/broadcaster/broadcaster";
import { AppEvent } from "../application/broadcaster/app.event";
import { AuthUser } from "./auth-user";
import { CookieStorage } from "../storage/cookie-storage";

/*
 * Wrt IN-4614: this is a special case of a very short-lived storage facility, which has builtin expiration
 *  So we most likely don't need special handling here (other than what is already present)
 *  also the properties of the object itself is not susceptible to change, so the version number is really
 *  only here, just-in-case NTK decides to add more properties (not likely)
 */
// eslint-disable-next-line @typescript-eslint/no-type-alias
type SERIAL_VERSION = number;
const CURRENT_VERSION: SERIAL_VERSION = 1;

interface AuthUserDTO extends AuthUser {
    version: SERIAL_VERSION;
}

@Injectable()
export class AuthUserRepository {

    private static readonly USER_KEY: string = "user";

    public constructor(private readonly broadcaster: Broadcaster, private readonly cookieStorage: CookieStorage) {
    }

    public getAuthUser(): AuthUser | undefined {
        const cookie: AuthUserDTO | undefined = this.cookieStorage.getCookie(AuthUserRepository.USER_KEY) ?? undefined;
        if (cookie === undefined) {

            this.broadcaster.broadcast(AppEvent.AuthenticationRequired);
        }
        // eslint-disable-next-line one-var
        const user: AuthUserDTO | undefined = this.cookieStorage.getCookie(AuthUserRepository.USER_KEY);
        if (user !== undefined && user.version === CURRENT_VERSION) {

            return new AuthUser(
                user.username,
                user.roles,
                user.accessToken,
                user.tokenType,
                user.fullName,
                user.refreshToken,
                user.expires
            );

        }

        return undefined;

    }

    // eslint-disable-next-line no-warning-comments
    // TODO [PVO] Adjust to fit Spec-feed users
    public setAuthUser(authUser: AuthUser): void {

        const dto: AuthUserDTO = {
            version: CURRENT_VERSION,
            accessToken: authUser.accessToken,
            expires: authUser.expires,
            fullName: authUser.fullName,
            refreshToken: authUser.refreshToken,
            roles: authUser.roles,
            tokenType: authUser.tokenType,
            username: authUser.username,
            hasUserIndicataBackendRole(): boolean {

                return authUser.hasUserIndicataBackendRole();

            }
        };

        CookieStorage.setCookie(
            AuthUserRepository.USER_KEY,
            dto
        );

    }

    public removeAuthUser(): void {

        CookieStorage.removeCookie(AuthUserRepository.USER_KEY);

    }

}
