<div (click)="onSort()" class="sortable">
    {{ label }}
    <div class="float-right">
        <fa-icon [icon]="faSort" *ngIf="!isSelected()"></fa-icon>
        <span *ngIf="isSelected()">
            <fa-icon [icon]="faSortUp" aria-hidden="true" *ngIf="hasDirection('ASC')"></fa-icon>
            <fa-icon [icon]="faSortDown" aria-hidden="true" *ngIf="hasDirection('DESC')"></fa-icon>
        </span>
    </div>
</div>
